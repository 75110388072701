.editor-container {
  margin: 0 0 20px;
  border-radius: 0 0 4px 4px;
  max-width: 100%;
  color: #000;
  position: relative;
  line-height: 20px;
  font-weight: 400;
  background: #fff;
}

.editor-inner {
  background: #fff;
  position: relative;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.editor-input {
  min-height: 200px;
  resize: vertical;
  font-size: 15px;
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: 15px;
  caret-color: #444;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* Updated list styles */
.editor-input ul,
.editor-input ol {
  margin: 0;
  padding-left: 40px;
  list-style-position: outside;
}

.editor-input li {
  margin: 8px 0;
}

/* Nested lists */
.editor-input ul ul,
.editor-input ul ol,
.editor-input ol ul,
.editor-input ol ol {
  margin-left: 20px;
}

/* Remove margin from paragraph inside list items */
.editor-input li p {
  margin: 0;
}

/* Quote styles */
.editor-input blockquote {
  margin: 8px 0;
  padding-left: 16px;
  border-left: 4px solid #ccc;
}

.editor-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 65px; /* Updated to account for toolbar height */
  left: 10px;
  font-size: 15px;
  user-select: none;
  pointer-events: none;
  padding: 15px;
}

/* Add list indentation styles */
.editor-list-ol {
  padding-left: 20px;
}

.editor-list-ul {
  padding-left: 20px;
}

.editor-listitem {
  margin: 8px 32px;
}

.editor-nested-listitem {
  list-style-type: none;
}

/* Nested list indentation */
.editor-list-ol .editor-list-ol,
.editor-list-ul .editor-list-ul {
  padding-left: 20px;
  margin-left: 20px;
}

.editor-text-bold {
  font-weight: bold;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-underline {
  text-decoration: underline;
}

.editor-paragraph {
  margin: 0 0 15px 0;
  position: relative;
}

.toolbar {
  border-bottom: none !important;
  padding: 5px;
  margin-bottom: 5px;
  display: flex;
  gap: 5px;
}

.toolbar .btn-light {
  border: 1px solid #dee2e6;
}

.toolbar .btn-light:hover {
  background-color: #e9ecef;
}

.toolbar .btn-light.active {
  background-color: #e2e6ea;
  color: #0056b3;
}

.toolbar .btn-light i {
  font-size: 14px;
  width: 14px;
  height: 14px;
  line-height: 14px;
}

.toolbar-button {
  border: none;
  background: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.toolbar-button:hover {
  background-color: #e9ecef;
}

.toolbar-button.active {
  background-color: #e2e6ea;
  color: #0056b3;
}

.toolbar-button i {
  font-size: 14px;
}

.toolbar-divider {
  width: 1px;
  background-color: #ccc;
  margin: 0 8px;
  height: 24px;
}
